// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/fonts/AvertaPE-Bold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/src/fonts/AvertaPE-Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("/src/fonts/AvertaPE-Semibold.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'AvertaPE Bold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('opentype');
  font-display: swap;
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'AvertaPE Regular';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('opentype');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvertaPE Semibold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('opentype');
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'AvertaPE Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  font-size: 16px;
  background: #f3f4f6;
}

code {
  font-family: 'AvertaPE Regular', sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,+DAA2D;EAC3D,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,+BAA+B;EAC/B,+DAA8D;EAC9D,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,gCAAgC;EAChC,+DAA+D;EAC/D,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,2CAA2C;EAC3C,mCAAmC;EACnC,kCAAkC;EAClC,WAAW;EACX,YAAY;EACZ,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,sBAAsB;EACtB,SAAS;EACT,UAAU;AACZ;AACA;;EAEE,gBAAgB;EAChB,qBAAqB;AACvB","sourcesContent":["@font-face {\n  font-family: 'AvertaPE Bold';\n  src: url('/src/fonts/AvertaPE-Bold.otf') format('opentype');\n  font-display: swap;\n  font-weight: bold;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'AvertaPE Regular';\n  src: url('/src/fonts/AvertaPE-Regular.otf') format('opentype');\n  font-display: swap;\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'AvertaPE Semibold';\n  src: url('/src/fonts/AvertaPE-Semibold.otf') format('opentype');\n  font-display: swap;\n  font-weight: 600;\n  font-style: normal;\n}\n\nbody {\n  margin: 0;\n  font-family: 'AvertaPE Regular', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  width: 100%;\n  height: 100%;\n  font-size: 16px;\n  background: #f3f4f6;\n}\n\ncode {\n  font-family: 'AvertaPE Regular', sans-serif;\n}\n\n* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n}\nul,\nli {\n  list-style: none;\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
