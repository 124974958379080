import React from 'react';
import {
  BoxMain,
  TextBox,
  LineBanner,
  Title,
  Paragraph,
  PrivacyPolicyLink,
  BoxContent,
} from './DataAgreementBlock.style';
import Container from 'index.style';
import LineBaner from 'assets/img/LineBanner.webp';

export const DataAgreementBlock = () => {
  return (
    <>
      <LineBanner src={LineBaner} alt='line' />
      <Container>
        <BoxMain>
          <TextBox>
            <h3>Соглашение о данных</h3>
          </TextBox>
          <BoxContent>
            <Title>1. Общие положения</Title>
            <Paragraph>
              1.1. Настоящее Соглашение о данных (далее – Соглашение) определяет условия работы с
              данными пользователей, собираемых посредством интернет-ресурса ваши данные.kz»,
              расположенного по адресу:{' '}
              <PrivacyPolicyLink href='https://ваши данные.kz'>
                https://ваши данные.kz
              </PrivacyPolicyLink>
              , и сопутствующих сервисов и инструментов указанного интернет-ресурса.
            </Paragraph>
            <Paragraph>
              1.2. Интернет-ресурс ваши данные.kz» является собственностью ТОО ваши данные и
              находится под его управлением.
            </Paragraph>
            <Paragraph>
              Перечисленные ниже термины имеют для целей настоящего Соглашения следующее значение:
            </Paragraph>
            <Paragraph>
              1.3.1 Сайт – интернет-ресурс ваши данные.kz», то есть совокупность размещенных в сети
              электронных документов (файлов), объединенных единой темой, дизайном и единым адресным
              пространством домена ваши данные.kz. Стартовая страница Сайта размещена в сети
              Интернет по адресу{' '}
              <PrivacyPolicyLink href='https://ваши данные.kz'>
                https://ваши данные.kz
              </PrivacyPolicyLink>
              ;
            </Paragraph>
            <Paragraph>
              1.3.2 Администратор – ТОО ваши данные, которому принадлежат все соответствующие
              имущественные права на Сайт, включая права на доменное имя Сайта, и осуществляющее его
              администрирование;
            </Paragraph>
            <Paragraph>
              1.3.3 Пользователь – лицо, обладающее необходимой дееспособностью для заключения и
              исполнения настоящего Соглашения, прошедшее процедуру регистрации на Сайте и
              получившее индивидуальный Аккаунт; для целей настоящего Соглашения в части работы с
              персональными данными под Пользователями также понимаются физические лица,
              представляющие Пользователя на соответствующих основаниях (трудовой договор,
              доверенность и т.п.);
            </Paragraph>
            <Paragraph>1.3.4 Стороны – Администратор и Пользователь.</Paragraph>
            <Paragraph>
              1.4. Настоящее Соглашение регулирует отношения между Администратором и Пользователем
              Сайта и является публичной офертой согласно статье 395 Гражданского кодекса Республики
              Казахстан. Полным и безоговорочным принятием (акцептом) условий Соглашения
              Пользователем считается подписание Соглашения электронной цифровой подписью Заказчика,
              выданной Национальным удостоверяющим центром Республики Казахстан. В случае несогласия
              Пользователя с каким-либо из положений Соглашения, Пользователь не вправе использовать
              Сайт.
            </Paragraph>
            <Paragraph>
              1.5. Администратор вправе в одностороннем порядке без предварительного уведомления
              изменить условия настоящего Соглашения с доведением до сведения Пользователя путем
              размещения Соглашения в новой редакции на Сайте. Пользователь обязуется самостоятельно
              регулярно отслеживать изменения в Соглашении. Продолжение использования Сайта
              Пользователем означает принятие Соглашения и изменений, внесенных в настоящее
              Соглашение. При несогласии Пользователя с внесенными изменениями, он обязан прекратить
              использование Сайта.
            </Paragraph>
            <Title>
              2. Сбор, обработка, хранение, использование, защита, уничтожение данных пользователя
            </Title>
            <Paragraph>
              2.1. Администратор обеспечивает конфиденциальность и сохранность данных Пользователя,
              предоставленных Пользователем на Сайте.
            </Paragraph>
            <Paragraph>
              2.2. В соответствии с законодательством Республики Казахстан в сфере защиты
              персональных данных Администратор вправе осуществлять сбор, обработку, хранение,
              использование, передачу (распространение, предоставление доступа) и уничтожение (все
              вместе далее по тексту «обработка») любой информации Пользователя, на основании
              которой можно отнести или определить Пользователя как субъекта персональных данных
              (далее – персональные данные), относящейся прямо или косвенно к Пользователю и иными
              связанными с Пользователем лицами, которая становится доступна Администратору в любой
              момент времени, в период действия регистрации Пользователя на Сайте.
            </Paragraph>
            <Paragraph>
              2.3. Обработка персональных данных осуществляется в строгом соответствии с Законом
              Республики Казахстан «О персональных данных и их защите».
            </Paragraph>
            <Paragraph>Обработке подвергаются следующие персональные данные:</Paragraph>
            <Paragraph>- фамилия, имя и отчество (при наличии);</Paragraph>
            <Paragraph>- индивидуальный идентификационный номер (ИИН);</Paragraph>
            <Paragraph>- адрес электронной почты;</Paragraph>
            <Paragraph>- контактный номер мобильного телефона.</Paragraph>
            <Paragraph>
              2.5. Регистрируясь на Сайте, а также подписывая настоящее Соглашение, Пользователь
              соглашается с законностью сбора и обработки данных Пользователя и/или представляющих
              его физических лиц, в том числе персональных, а также дает согласие на обработку
              данных, в том числе персональных, их передачу, без ограничения срока действия, до тех
              пор, пока Пользователь не обратиться с требованием или пожеланием прекращения
              обработки своих персональных данных и/или их уничтожения.
            </Paragraph>
            <Paragraph>
              2.6. Отзыв согласия на обработку персональных данных производится путем направления
              соответствующего сообщения по адресу электронной почты Администратора. В этом случае
              доступ Пользователя к Сайту блокируется, соответствующие данные Пользователя
              уничтожаются.
            </Paragraph>
            <Paragraph>
              2.7. Администратор обязуется обеспечивать соблюдение требований действующего
              законодательства Республики Казахстан в сфере защиты персональных данных при обработке
              персональных данных Пользователя. Администратор принимает все меры предосторожности,
              включая правовые, организационные, административные, технические и физические меры,
              для защиты данных Пользователя, в том числе персональных, от несанкционированного или
              случайного доступа - изменения, копирования, блокирования, уничтожения,
              распространения или других неправомерных действий третьих лиц.
            </Paragraph>
            <Paragraph>
              2.8. Администратор вправе в необходимом объеме предоставлять для целей, указанных в
              соответствующей оферте на Сайте, персональные данные Пользователя контрагентам, а
              также государственным органам, уполномоченным запрашивать Персональные данные в
              соответствии с законодательством Республики Казахстан.
            </Paragraph>
            <Paragraph>
              2.9. Обработка персональных данных Пользователя будет осуществляться исключительно для
              принятия решений или совершения иных действий, относящихся к вопросам сотрудничества с
              Пользователем в связи с его регистрацией на Сайте и использованием Сайта.
            </Paragraph>
            <Paragraph>
              2.10. Администратор использует данные Пользователя, в том числе: для выполнения своих
              обязательств перед Пользователем; для оценки и анализа работы Сайта.
            </Paragraph>
            <Paragraph>
              2.11. Администратор хранит данные Пользователя, в том числе персональные, в течение
              срока, необходимого для выполнения целей, указанных в настоящем Соглашении. Более
              длительный срок хранения данных возможен только в случаях, когда это необходимо в
              соответствии с законодательством Республики Казахстан.
            </Paragraph>
            <Paragraph>
              2.12. Администратор вправе использовать технологию «cookies». «Cookies» не содержат
              конфиденциальную информацию и не передаются третьим лицам. Администратор получает
              информацию об IP-адресе посетителя Сайта. Данная информация не используется для
              идентификации Пользователя.
            </Paragraph>
            <Paragraph>
              2.13. Пользователь имеет возможность проверить предоставленные им данные, в том числе
              персональные, а также внести в них изменения в случае необходимости, в личном кабинете
              Пользователя на Сайте.
            </Paragraph>
            <Paragraph>
              2.14. Пользователь несет ответственность за достоверность и актуальность передаваемых
              Администратору персональных данных.
            </Paragraph>
            <Paragraph>
              2.15. Пользователь обязуется обеспечивать конфиденциальность данных, соблюдение
              требований действующего законодательства Республики Казахстан, в том числе в сфере
              защиты персональных данных, при получении доступа к данным иных пользователей Сайта.
            </Paragraph>
            <Paragraph>
              2.16. Пользователь обязан получить соответствующие согласия его
              работников/представителей на обработку их персональных данных. Пользователь не должен
              допускать публикацию на Сайте персональных данных своих работников/представителей в
              случае отсутствия указанных согласий.
            </Paragraph>
            <Title>3. Заключительные положения</Title>
            <Paragraph>
              3.1. Все споры, возникающие из Соглашения или связанные с ним, могут быть переданы на
              разрешение суда после принятия Сторонами мер по досудебному урегулированию спора по
              истечении 30 (тридцати) календарных дней со дня направления первой претензии. Если
              спор не урегулирован в досудебном порядке согласно настоящему пункту, он может быть
              передан на разрешение суда в соответствии с действующим законодательством Республики
              Казахстан по месту нахождения Администратора.
            </Paragraph>
            <Paragraph>
              3.2. В части, не урегулированной настоящим Соглашением, Стороны регулируются нормами
              законодательства Республики Казахстан.
            </Paragraph>
          </BoxContent>
        </BoxMain>
      </Container>
    </>
  );
};
